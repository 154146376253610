import React from "react";
// @material-ui/core components
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import UsersList from "components/UsersList";

const Users = () => {
  return (
    <Card>
      <CardHeader color="primary">Usuarios</CardHeader>
      <CardBody>
        <UsersList />
      </CardBody>
    </Card>
  );
};

export default Users;
