import React, { useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  Typography,
} from "@material-ui/core";
import useStyles from "./styles";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

const FiltersUsers = ({ listUsers, setListUsersFiltered }) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [selectedDateStart, setSelectedDateStart] = useState(new Date());
  const [selectedDateFinish, setSelectedDateFinish] = useState(new Date());

  const handleDateChangeStart = (date) => {
    setSelectedDateStart(date);
  };

  const handleDateChangeFinish = (date) => {
    setSelectedDateFinish(date);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleApplyFilter = () => {
    setOpen(false);
    const userFiltrados = listUsers.filter((user) => {
      const fechaJoin = new Date(user.date_joined);
      return fechaJoin >= selectedDateStart && fechaJoin <= selectedDateFinish;
    });
    setListUsersFiltered(userFiltrados);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const deleteFilter = () => {
    setListUsersFiltered(listUsers);
    setOpen(false);
    setSelectedDateStart(new Date());
    setSelectedDateFinish(new Date());
  };
  const DialogTitle = (props) => {
    const { children, onClose, ...other } = props;
    return (
      <MuiDialogTitle
        disableTypography
        className={classes.dialogTitle}
        {...other}
      >
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  };

  return (
    <>
      <Box mt={1} mb={2}>
        <Button variant="outlined" color="secondary" onClick={handleClickOpen}>
          Filtros
        </Button>
      </Box>
      <Dialog
        maxWidth="md"
        open={open}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle onClose={handleClose} id="max-width-dialog-title">
          Filtros
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={4}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Box display="flex">
                <Box p={1}>
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="MM/dd/yyyy"
                    margin="normal"
                    label="Fecha Inicio"
                    value={selectedDateStart}
                    onChange={handleDateChangeStart}
                  />
                </Box>

                <Box p={1}>
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="MM/dd/yyyy"
                    margin="normal"
                    label="Fecha Fin"
                    value={selectedDateFinish}
                    onChange={handleDateChangeFinish}
                  />
                </Box>
              </Box>
            </MuiPickersUtilsProvider>
          </Grid>

          <Box mt={2}>
            <DialogActions className={classes.dialogActions}>
              <Button
                onClick={deleteFilter}
                color="secondary"
                variant="outlined"
              >
                Borrar Filtros
              </Button>
              <Button
                aria-label="Cerrar Alert Dialog"
                id="CerrarAlertDialog"
                color="primary"
                variant="outlined"
                onClick={handleApplyFilter}
              >
                Aplicar Filtro
              </Button>
            </DialogActions>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default FiltersUsers;
