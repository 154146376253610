import { colors } from "@material-ui/core";

const white = "#FFFFFF";
const black = "#000000";
const purple = "#2f2b50";

export default {
  black,
  white,
  purple,
  primary: {
    contrastText: white,
    dark: colors.indigo[900],
    main: purple,
    light: black,
  },
  secondary: {
    dark: purple,
    main: purple,
    light: purple,
    contrastText: white,
  },
  success: {
    contrastText: white,
    dark: colors.green[900],
    main: colors.green[600],
    light: colors.green[400],
  },
  info: {
    contrastText: colors.blue[900],
    dark: colors.blue[900],
    main: colors.blue[600],
    light: colors.blue[400],
  },
  warning: {
    contrastText: white,
    dark: colors.purple[900],
    main: colors.purple[600],
    light: colors.purple[400],
  },
  error: {
    contrastText: white,
    dark: colors.red[900],
    main: colors.red[600],
    light: colors.red[400],
  },
  text: {
    primary: colors.blueGrey[900],
    secondary: colors.blueGrey[600],
    link: colors.blue[600],
  },
  background: {
    default: "#F4F6F8",
    paper: white,
  },
  icon: purple,
  divider: colors.grey[200],
};
