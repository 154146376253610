import React, { useState } from "react";
import MUIDataTable from "mui-datatables";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { formatDateHours } from "helpers";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { Link } from "@material-ui/core";

const DataTableHistory = ({ dataList, isSend }) => {
  const [selectedDateStart, setSelectedDateStart] = useState(new Date());
  const [selectedDateFinish, setSelectedDateFinish] = useState(new Date());
  const [gearHistoryList, setGearHistoryList] = useState(dataList);

  const handleDateChangeStart = (date) => {
    setSelectedDateStart(date);
    const gearFiltrados = dataList.filter((gear) => {
      const fechaGear = new Date(gear.created);
      return fechaGear >= date && fechaGear <= selectedDateFinish;
    });
    setGearHistoryList(gearFiltrados);
  };

  const handleDateChangeFinish = (date) => {
    setSelectedDateFinish(date);
    const gearFiltrados = dataList.filter((gear) => {
      const fechaGear = new Date(gear.created);
      return fechaGear >= selectedDateStart && fechaGear <= date;
    });
    setGearHistoryList(gearFiltrados);
  };

  const onClearFilters = () => {
    setGearHistoryList(dataList);
  };

  const options = {
    filterType: "dropdown",
    responsive: "scroll",
    selectableRowsHeader: false,
    selectableRows: "none",
    expandableRowsHeader: false,
    expandableRows: true,
    renderExpandableRow: (_, rowMeta) => {
      const dataRowByIndex = gearHistoryList[rowMeta.dataIndex];
      return (
        <Box m={2}>
          <Box mt={1}>
            <b>Gear ID: </b>
            <Typography>{dataRowByIndex.gear_id}</Typography>
          </Box>

          <Box mt={1}>
            <b>Created: </b>
            <Typography>{formatDateHours(dataRowByIndex.created)}</Typography>
          </Box>

          {isSend ? (
            <Box mt={1}>
              <b>Response: </b>
              <Typography>{dataRowByIndex.response}</Typography>
            </Box>
          ) : (
            <>
              <b>Data: </b>
              {!!dataRowByIndex?.raw?.length &&
                dataRowByIndex.raw.map((item, index) => (
                  <p style={{ marginLeft: "1rem" }} key={index}>
                    <b>{item.name} </b>
                    {typeof item.value === "object" ? (
                      <p>{JSON.stringify(item.value).replaceAll(`:`, ` : `)}</p>
                    ) : (
                      item.value
                    )}
                  </p>
                ))}
            </>
          )}

          <Box mt={1}>
            <b>Data: </b>
            <Box mt={1}>
              {dataRowByIndex?.parsed_data?.map((item, index) => (
                <p style={{ marginLeft: "1rem" }} key={index}>
                  <b>{item.name} </b>
                  {typeof item.value === "object" ? (
                    <p>{JSON.stringify(item.value).replaceAll(`:`, ` : `)}</p>
                  ) : (
                    item.value
                  )}
                </p>
              ))}
            </Box>
          </Box>
        </Box>
      );
    },
  };

  let columns = [
    {
      name: "gear_name",
      label: "Nombre",
      options: {
        filter: true,
      },
    },
    {
      name: "created",
      label: "Fecha",
      options: {
        filter: true,
        customBodyRender: (value) => formatDateHours(value),
      },
    },
    {
      name: "state",
      label: "Estado",
      options: {
        filter: false,
        customBodyRender: (value) => (
          <span style={{ color: value.color }}>{value.text}</span>
        ),
      },
    },
  ];
  //Se elimina la columna de estado para los "download"
  if (!isSend) {
    columns = columns.filter((col) => col.name !== "state");
  }
  return (
    <>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Box p={1}>
          <Box display="flex">
            <Box p={1}>
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="MM/dd/yyyy"
                margin="normal"
                label="Fecha Inicio"
                value={selectedDateStart}
                onChange={handleDateChangeStart}
              />
            </Box>
            <Box p={1}>
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="MM/dd/yyyy"
                margin="normal"
                label="Fecha Fin"
                value={selectedDateFinish}
                onChange={handleDateChangeFinish}
              />
            </Box>
          </Box>
          <Box ml={1}>
            <Link
              href="#"
              underline="always"
              onClick={onClearFilters}
              color="inherit"
            >
              Limpiar Filtros
            </Link>
          </Box>
        </Box>
      </MuiPickersUtilsProvider>
      <MUIDataTable
        data={gearHistoryList}
        columns={columns}
        options={options}
      />
    </>
  );
};

export default DataTableHistory;
