export const formatDate = (date) => {
  try {
    if (date) {
      const dateFormat = new Date(date);
      var options = { year: "numeric", month: "long", day: "numeric" };

      return dateFormat.toLocaleDateString("es-ES", options);
    } else {
      return "[Sin Fecha]";
    }
  } catch (error) {
    return "";
  }
};

function padTo2Digits(num) {
  return num.toString().padStart(2, "0");
}

export const formatDateHours = (date) => {
  try {
    if (date) {
      const dateFormat = new Date(date);
      return (
        [
          dateFormat.getFullYear(),
          padTo2Digits(dateFormat.getMonth() + 1),
          padTo2Digits(dateFormat.getDate()),
        ].join("-") +
        " " +
        [
          padTo2Digits(dateFormat.getHours()),
          padTo2Digits(dateFormat.getMinutes()),
          padTo2Digits(dateFormat.getSeconds()),
        ].join(":")
      );
    } else {
      return "[Sin Fecha]";
    }
  } catch (error) {
    return "";
  }
};
