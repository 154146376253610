import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(({ palette, shadows }) => ({
  circle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 20,
    height: 20,
    borderRadius: "50%",
    backgroundColor: palette.background.default,
    boxShadow: shadows[1],
  },
  active: {
    backgroundColor: palette.secondary.main,
  },
}));
export default useStyles;
