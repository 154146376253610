import axios from "axios";
const APP_API_GP =
  window.location.hostname === "localhost"
    ? "https://devtest.gearplug.io/"
    : "https://api.gearplug.io/";

export const apiAuthGP = axios.create({
  baseURL: `${APP_API_GP}management/api`,
  headers: {
    "Content-Type": "application/json;charset=UTF-8",
  },
});

export const apiGP = axios.create({
  baseURL: `https://devtest.gearplug.io/api`,
  headers: {
    "Content-Type": "application/json;charset=UTF-8",
  },
});

export const apiAuthHistoryGP = axios.create({
  baseURL: `${APP_API_GP}history/api/`,
  headers: {
    "Content-Type": "application/json;charset=UTF-8",
  },
});

export const apiGPForAuth = axios.create({
  baseURL: `${APP_API_GP}auth/`,
  headers: { "Content-Type": "application/json;charset=UTF-8" },
});
