import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import validate from "validate.js";
import { Grid, Button, TextField, Typography, Box } from "@material-ui/core";
import { useForm } from "hooks/useForm";
import { errorAlert, loader } from "../../misc/alerts";
import useStyles from "./styles";
import { setToken } from "store/slices/sesion";
import { useDispatch } from "react-redux";
import { apiGPForAuth } from "config/clienteAxios";

const schema = {
  email: {
    presence: { allowEmpty: false, message: "Es requerido" },
    length: {
      maximum: 64,
    },
  },
  password: {
    presence: { allowEmpty: false, message: "Es requerido" },
    length: {
      maximum: 128,
    },
  },
};

const SignIn = (props) => {
  const { history } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const [formValues, handleInputChange] = useForm({
    email: "",
    password: "",
  });
  const [errors, setErrors] = useState({ msg: "", errorField: "" });

  const getLogin = async () => {
    loader("Iniciando Sesion");
    try {
      const response = await apiGPForAuth.post("login/", {
        email: formValues.email,
        password: formValues.password,
      });
      if (response?.data?.key && response?.data?.user?.is_staff) {
        dispatch(setToken(`Bearer ${response?.data?.key}`));
        loader("Iniciando Sesion", true);
        history.push("/admin/users");
      } else {
        errorAlert("No tiene permisos para acceder");
      }
    } catch (error) {
      errorAlert("Error al iniciar sesion");
    }
  };

  const handleSignIn = (event) => {
    event.preventDefault();
    // Valida que los campos esten correctos
    const hasErrors = validate(formValues, schema);
    if (hasErrors?.email) {
      setErrors({ msg: hasErrors?.email[0], errorField: "email" });
    } else if (hasErrors?.password) {
      setErrors({ msg: hasErrors?.password[0], errorField: "password" });
    } else {
      setErrors({ msg: "", errorField: "" });
      getLogin();
    }
  };

  const hasError = (field) => {
    return errors.errorField === field;
  };

  return (
    <Grid className={classes.content} item lg={12} xs={12}>
      <Box className={classes.contentBody} mt={{ xs: 5, md: 20 }}>
        <form className={classes.form} onSubmit={handleSignIn}>
          <Typography className={classes.title} variant="h2">
            Inicia Sesion
          </Typography>

          <TextField
            className={classes.textField}
            fullWidth
            label="Email address"
            name="email"
            type="text"
            color="secondary"
            variant="outlined"
            error={hasError("email")}
            helperText={hasError("email") ? errors.msg : null}
            value={formValues.email}
            onChange={handleInputChange}
          />
          <TextField
            className={classes.textField}
            fullWidth
            color="secondary"
            label="Password"
            name="password"
            type="password"
            error={hasError("password")}
            helperText={hasError("password") ? errors.msg : null}
            variant="outlined"
            value={formValues.password}
            onChange={handleInputChange}
          />
          <Button
            className={classes.signInButton}
            color="secondary"
            fullWidth
            size="large"
            type="submit"
            aria-label="sign In Button"
            id="signInButton"
            variant="contained"
          >
            Iniciar
          </Button>
        </form>
      </Box>
    </Grid>
  );
};

export default withRouter(SignIn);
