import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import DashboardUserDetail from "./DashboardUserDetail";
import { Typography } from "@material-ui/core";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function DetailUser({ open, setOpen, detalleUser }) {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      maxWidth="lg"
      fullWidth
      onClose={handleClose}
    >
      <DialogTitle>
        {`Cliente: ${detalleUser.full_name}`}
        <Typography>{detalleUser.email}</Typography>
      </DialogTitle>
      <DialogContent>
        <DashboardUserDetail detalleUser={detalleUser} />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cerrar
        </Button>
      </DialogActions>
    </Dialog>
  );
}
