import React, { useState } from "react";
import { IconButton } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { useSelector, useDispatch } from "react-redux";
import { setToken } from "store/slices/sesion";
import { apiAuthHistoryGP } from "config/clienteAxios";
import DetailGearHistory from "./DetailGearHistory";
import { loader } from "misc/alerts";
import SwitchMyGear from "./SwitchMyGear";

const TableGearsCreados = ({ gears }) => {
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.sesion);
  const [openDetailGear, setOpenDetailGear] = useState(false);
  const [dataDetailGear, setDataDetailGear] = useState({
    listDataSend: [],
    listDataDownload: [],
  });

  //Logica para obtener el detalle del gear
  const handleClickOpen = async (idGear) => {
    // idGear = "wPaKZEG";
    loader("Cargando Lista de gears");
    try {
      const { data } = await apiAuthHistoryGP.get(
        `send/management/?gear_id=${idGear}`,
        {
          headers: { Authorization: token },
        }
      );
      const { data: dataDownload } = await apiAuthHistoryGP.get(
        `download/management/?gear_id=${idGear}`,
        {
          headers: { Authorization: token },
        }
      );
      setOpenDetailGear(true);
      const detalleGear = {
        listDataSend: data,
        listDataDownload: dataDownload,
      };
      setDataDetailGear(detalleGear);
    } catch (error) {
      dispatch(setToken(""));
    } finally {
      loader("Cargando Lista de usuarios", true);
    }
  };

  const handleCloseDetail = () => {
    setDataDetailGear({});
    setOpenDetailGear(false);
  };

  const options = {
    filterType: "dropdown",
    responsive: "scroll",
    selectableRowsHeader: false,
    selectableRows: "none",
  };
  const columns = [
    {
      name: "name",
      label: "Nombre",
      options: {
        filter: true,
      },
    },
    {
      name: "id",
      label: "Estado",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => (
          <IconButton onClick={() => handleClickOpen(value)}>
            <VisibilityIcon />
          </IconButton>
        ),
      },
    },
    {
      name: "id",
      label: "Estatus",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => {
          const gearData = gears.find((gear) => gear.id === value);
          return <SwitchMyGear gearData={gearData} />;
        },
      },
    },
  ];
  return (
    <>
      <MUIDataTable data={gears} columns={columns} options={options} />
      <DetailGearHistory
        openDetailGear={openDetailGear}
        dataDetailGear={dataDetailGear}
        handleCloseDetail={handleCloseDetail}
      />
    </>
  );
};

export default TableGearsCreados;
