import React from "react";
import { Router, Route, Switch } from "react-router-dom";
import { createBrowserHistory } from "history";

// core components
import Admin from "layouts/Admin.js";
import Login from "./views/Login";
import { Provider } from "react-redux";
import "assets/css/material-dashboard-react.css?v=1.9.0";
import { ThemeProvider } from "@material-ui/core";
import theme from "theme";
import store, { persistor } from "./store";
import { PersistGate } from "redux-persist/integration/react";
const App = () => {
  const hist = createBrowserHistory();

  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <Router history={hist}>
          <Switch>
            <ThemeProvider theme={theme}>
              <Route path="/login" component={Login} />
              <Route path="/" component={Admin} />
            </ThemeProvider>
          </Switch>
        </Router>
      </PersistGate>
    </Provider>
  );
};

export default App;
