import React, { useState, forwardRef } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";
import DataTableHistory from "./DataTableHistory";
import TabPanel from "components/TabPanel";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import SwipeableViews from "react-swipeable-views";
import { useTheme } from "@material-ui/core";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DetailGearHistory = ({
  openDetailGear,
  dataDetailGear,
  handleCloseDetail,
}) => {
  const theme = useTheme();
  const [valueTabs, setValueTabs] = useState(0);
  const handleChange = (_, newValue) => {
    setValueTabs(newValue);
  };
  const handleChangeIndex = (index) => {
    setValueTabs(index);
  };
  return (
    <Dialog
      open={openDetailGear}
      TransitionComponent={Transition}
      maxWidth="md"
      fullWidth
      onClose={handleCloseDetail}
    >
      <DialogContent>
        <AppBar position="static" color="default">
          <Tabs
            value={valueTabs}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
          >
            <Tab label="Send" />
            <Tab label="Download" />
          </Tabs>
        </AppBar>

        <SwipeableViews
          axis={theme.direction === "rtl" ? "x-reverse" : "x"}
          index={valueTabs}
          onChangeIndex={handleChangeIndex}
        >
          <TabPanel value={valueTabs} index={0} dir={theme.direction}>
            {!!dataDetailGear?.listDataSend?.length && (
              <DataTableHistory
                dataList={dataDetailGear.listDataSend}
                isSend={true}
              />
            )}
          </TabPanel>

          <TabPanel value={valueTabs} index={1} dir={theme.direction}>
            {!!dataDetailGear?.listDataDownload?.length && (
              <DataTableHistory dataList={dataDetailGear.listDataDownload} />
            )}
          </TabPanel>
        </SwipeableViews>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleCloseDetail} color="primary">
          Cerrar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DetailGearHistory;
