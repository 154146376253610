import Person from "@material-ui/icons/People";
import Users from "views/Users";

const dashboardRoutes = [
  {
    path: "/user",
    name: "Usuarios",
    icon: Person,
    component: Users,
    layout: "/admin",
  },
];

export default dashboardRoutes;
