const { makeStyles } = require("@material-ui/core");

const useStyles = makeStyles((theme) => ({
  marginElement: {
    marginTop: theme.spacing(3),
  },
  formControl: {
    width: "100%",
  },
  colorControls: {
    color: "black !important",
    "& span": {
      "& span": {
        "& span": {
          width: "60px",
          height: "60px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-around",
          alignItems: "center",
        },
      },
    },
  },
  dialogActions: {
    justifyContent: "space-between",
  },
  dialogTitle: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));
export default useStyles;
