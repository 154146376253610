import React, { useState } from "react";
import MUIDataTable from "mui-datatables";
import DetailUser from "./DetailUser";
import VisibilityIcon from "@material-ui/icons/Visibility";
import IconButton from "@material-ui/core/IconButton";
import FiltersUsers from "./FiltersUsers";
import { useEffect } from "react";
import { apiAuthGP } from "config/clienteAxios";
import { errorAlert, loader } from "misc/alerts";
import { useSelector } from "react-redux";
import { setToken } from "store/slices/sesion";
import { useDispatch } from "react-redux";
import { formatDate } from "helpers";

const UsersList = () => {
  const { token } = useSelector((state) => state.sesion);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const [listUsers, setListUsers] = useState([]);
  const [listUsersFiltered, setListUsersFiltered] = useState([]);
  const [loadingListUsers, setLoadingListUsers] = useState(false);

  const [detalleUser, setDetalleUser] = useState({});

  //Obtiene el listado de los usuarios
  useEffect(() => {
    const getListUsers = async () => {
      setLoadingListUsers(true);
      loader("Cargando Lista de usuarios");
      try {
        const response = await apiAuthGP.get("/endpoint1/", {
          headers: { Authorization: token },
        });
        const listUserFormat = response.data.map((item) => {
          item.ConsumedCredits = item.total_recharged - item.current_balance;
          return item;
        });
        setListUsers(listUserFormat);
        setListUsersFiltered(listUserFormat);
        loader("Cargando Lista de usuarios", true);
      } catch (error) {
        errorAlert("Error al obtener usuarios");
        dispatch(setToken(""));
      } finally {
        setLoadingListUsers(false);
      }
    };
    getListUsers();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  //Logica para obtener el detalle del usuario
  const handleClickOpen = async (idUser) => {
    loader("Cargando detalle de usuario");
    try {
      const response = await apiAuthGP.get(`/endpoint1/${idUser}`, {
        headers: { Authorization: token },
      });
      setOpen(true);
      setDetalleUser(response.data);
      loader("Cargando detalle de usuario", true);
    } catch (error) {
      errorAlert("Error al obtener usuario");
      dispatch(setToken(""));
    }
  };

  const columns = [
    {
      name: "id",
      options: {
        display: false,
        filter: false,
      },
    },
    {
      name: "email",
      label: "Correo",
      options: {
        filter: false,
      },
    },
    {
      name: "date_joined",
      label: "Fecha de registro",
      options: {
        filter: false,
        customBodyRender: (value) => formatDate(value),
      },
    },
    {
      name: "plan",
      label: "Plan",
      options: {
        filter: true,
        customBodyRender: (value) => value ?? "Free",
      },
    },
    {
      name: "ConsumedCredits",
      label: "Creditos Consumidos",
      options: {
        filter: false,
      },
    },
    {
      name: "total_gears_count",
      label: "Gear Creados",
      options: {
        filter: false,
      },
    },
    {
      name: "active_gears_count",
      label: "Gears Activos ",
      options: {
        filter: false,
      },
    },
    {
      name: "id",
      label: "Detalle",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => (
          <IconButton onClick={() => handleClickOpen(value)}>
            <VisibilityIcon />
          </IconButton>
        ),
      },
    },
  ];

  const options = {
    filter: true,
    filterType: "dropdown",
    responsive: "scroll",
    selectableRowsHeader: false,
    selectableRows: "none",
  };

  if (loadingListUsers) return <>Cargando...</>;
  return (
    <>
      <FiltersUsers
        listUsers={listUsers}
        setListUsersFiltered={setListUsersFiltered}
      />
      <MUIDataTable
        data={listUsersFiltered}
        columns={columns}
        options={options}
      />
      <DetailUser open={open} setOpen={setOpen} detalleUser={detalleUser} />
    </>
  );
};

export default UsersList;
