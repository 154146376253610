import MUIDataTable from "mui-datatables";
import React from "react";

const TableConnections = ({ connections }) => {
  const options = {
    filter: false,
    filterType: "dropdown",
    responsive: "scroll",
    search: false,
    selectableRowsHeader: false,
    selectableRows: "none",
  };
  const columns = [
    {
      name: "name",
      label: "Nombre",
      options: {
        filter: true,
      },
    },
    {
      name: "connector__name",
      label: "Aplicacion",
      options: {
        filter: false,
      },
    },
  ];
  return <MUIDataTable data={connections} columns={columns} options={options} />;
};

export default TableConnections;
