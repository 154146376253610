import { createSlice } from "@reduxjs/toolkit";

export const sesionSlice = createSlice({
  name: "sesion",
  initialState: {
    token: "",
  },
  reducers: {
    setToken: (state, action) => {
      state.token = action.payload;
    },
  },
});

export const { setToken } = sesionSlice.actions;

export default sesionSlice.reducer;
