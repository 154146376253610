import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import useStyles from "./styles";
import Switch from "@material-ui/core/Switch";

const SwitchLoader = ({ MUISwitchProps, loading }) => {
  const classes = useStyles();

  const Icon = () => (
    <div
      className={`${classes.circle} ${
        MUISwitchProps.checked && !loading ? classes.active : ""
      }`}
    >
      {loading && (
        <CircularProgress size={14} color="secondary" thickness={6} />
      )}
    </div>
  );

  return (
    <Switch
      checkedIcon={<Icon />}
      icon={<Icon />}
      color={MUISwitchProps.color}
      checked={MUISwitchProps.checked}
      onChange={MUISwitchProps.onChange}
      value="checkedA"
      inputProps={{ "aria-label": "Switch with loading state" }}
    />
  );
};

export default SwitchLoader;
