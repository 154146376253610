import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import SwitchLoader from "components/SwitchLoader";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { apiGP } from "config/clienteAxios";
import { errorAlert } from "misc/alerts";

const SwitchMyGear = ({ gearData }) => {
  const { token } = useSelector((state) => state.sesion);
  const [loadingSwitchMyGear, setLoadingSwitchMyGear] = useState(false);
  const [gearActive, setGearActive] = useState(gearData.is_active);

  useEffect(() => {
    setGearActive(gearData.is_active);
  }, [gearData]);

  const onSwitchMyGear = async (id) => {
    try {
      setLoadingSwitchMyGear(true);
      const { data } = await apiGP.post(
        `/gear/${id}/toggle/`,
        {},
        {
          headers: { Authorization: token },
        }
      );
      setGearActive(data.result);
    } catch (error) {
      errorAlert("Error al apagar/encender gear");
    } finally {
      setLoadingSwitchMyGear(false);
    }
  };

  return (
    <FormControlLabel
      control={
        <SwitchLoader
          MUISwitchProps={{
            color: "secondary",
            checked: gearActive,
            onChange: () => onSwitchMyGear(gearData.id),
          }}
          loading={loadingSwitchMyGear}
        />
      }
      label={gearActive ? "Activo" : "Inactivo"}
    />
  );
};

export default SwitchMyGear;
