import React from "react";
// react plugin for creating charts
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import DateRange from "@material-ui/icons/DateRange";
import AccountBalanceWalletTwoTone from "@material-ui/icons/AccountBalanceWalletTwoTone";
import ListAltIcon from "@material-ui/icons/ListAlt";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import LocalAtmIcon from "@material-ui/icons/LocalAtm";
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import Box from "@material-ui/core/Box";
import TableGearsCreados from "./TableGearsCreados";
import TableConnections from "./TableConnections";
import { formatDate } from "helpers";

const useStyles = makeStyles(styles);

export default function DashboardUserDetail({ detalleUser }) {
  const classes = useStyles();
  return (
    <>
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="success" stats icon>
              <CardIcon color="success">
                <LocalAtmIcon />
              </CardIcon>
              <Box m={2} display={"flex"} justifyContent="space-around">
                <Box>
                  <p className={classes.cardCategory}>Tipo Plan</p>
                  <h3 className={classes.cardTitle}>
                    {detalleUser.plan ?? "Free"}
                  </h3>
                </Box>
                <Box>
                  <p className={classes.cardCategory}>Creditos</p>
                  <h3 className={classes.cardTitle}>
                    {detalleUser.current_balance}
                  </h3>
                </Box>
              </Box>
            </CardHeader>
            <CardFooter stats>
              <Box>
                <div className={classes.stats}>
                  <AccountBalanceWalletTwoTone />
                  Creditos consumidos:{" "}
                  {detalleUser.total_recharged - detalleUser.current_balance}
                </div>
              </Box>
              <Box>
                <div className={classes.stats}>
                  <DateRange />
                  Fecha de renovación:{" "}
                  {formatDate(detalleUser.next_billing_date)}
                </div>
              </Box>
            </CardFooter>
          </Card>
        </GridItem>

        <GridItem xs={12}>
          <Card>
            <CardHeader color="primary" stats icon>
              <CardIcon color="primary">
                <ListAltIcon />
              </CardIcon>
              <Box m={2} display={"flex"} justifyContent="space-around">
                <Box>
                  <p className={classes.cardCategory}>Gear creados</p>
                  <h3 className={classes.cardTitle}>
                    {detalleUser.total_gears_count}
                  </h3>
                </Box>
                <Box>
                  <p className={classes.cardCategory}>Gear activos</p>
                  <h3 className={classes.cardTitle}>
                    {detalleUser.active_gears_count}
                  </h3>
                </Box>
              </Box>
            </CardHeader>
          </Card>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="primary">Gears creados</CardHeader>
            <CardBody>
              <TableGearsCreados gears={detalleUser.gears} />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>

      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="success">Conexiones</CardHeader>
            <CardBody>
              <TableConnections connections={detalleUser.connections} />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </>
  );
}
